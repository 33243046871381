<!--统计报表-监测点统计-->
<template>
  <section>
    <!-- 工具条 -->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form :inline="true" :model="filter" size="mini">
        <el-form-item>
          <el-input
            v-model.trim="filter.mn"
            placeholder="编码"
            @keyup.native.enter.stop="getData"
          >
            <el-button
              slot="prepend"
              icon="el-icon-refresh"
              @click="handleRefresh"
            ></el-button>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            placeholder="油烟健康码"
            v-model="filter.HealthCodeColor"
            @change="getData"
            clearable
          >
            <el-option label="红色" value="Red"/>
            <el-option label="黄色" value="Yellow"/>
            <el-option label="绿色" value="Green"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-cascader
            v-model="filter.owner"
            @change="getData"
            placeholder="所属单位"
            :options="customerTree"
            :props="orgProps"
            clearable
            filterable
          />
        </el-form-item>
        <el-form-item v-if="filter.option">
          <el-date-picker
            v-model="filter.peorid"
            type="daterange"
            value-format="timestamp"
            @change="getData"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-download"
            @click="openExportSizeInput"
          >导出
          </el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <!-- 列表 -->
    <el-col :span="24">
      <el-table
        :data="datalist.content"
        @sort-change="handleSort"
        show-summary
        size="mini"
        border
        highlight-current-row
        v-loading="loading"
        :max-height="clientHeight"
        style="width: 100%"
      >
        <el-table-column type="index" label="#" align="center" width="55"/>
        <el-table-column
          prop="Name"
          sortable="custom"
          label="监测点"
          width="180"
          show-overflow-tooltip
          header-align="center"
        />
        <el-table-column
          prop="MN"
          sortable="custom"
          label="设备编号"
          align="center"
          show-overflow-tooltip
          width="200"
          header-align="center"
        />
        <el-table-column
          prop="Addr"
          label="地址"
          show-overflow-tooltip
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="EmissionsSill"
          label="油烟排放限值(mg/m³)"
          width="140"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="CEmissionsMax"
          label="日最高油烟排放浓度(mg/m³)"
          width="160"
          align="center"
          header-align="center"
        >
          <template slot-scope="{row}">
            <div v-if="dateRange===row.AbnormalOfflineDays ">-</div>
            <div v-else>{{ row.CEmissionsMax }}</div>
          </template>

        </el-table-column>
        <el-table-column
          prop="CEmissionsMaxDate"
          label="最高油烟浓度日期"
          width="130"
          align="center"
          header-align="center"
        >
          <template slot-scope="{row}">
            <div v-if="dateRange===row.AbnormalOfflineDays ">-</div>
            <div v-else>{{ row.CEmissionsMaxDate ? row.CEmissionsMaxDate.substr(0, 10) : '' }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="CEmissionsAvgOnline"
          label="在线日平均油烟排放浓度(mg/m³)"
          width="200"
          align="center"
          header-align="center"
        >
        <template slot-scope="{row}">
          <div v-if="dateRange===row.AbnormalOfflineDays ">-</div>
          <div v-else>{{ row.CEmissionsAvgOnline }}</div>
        </template>
        </el-table-column>
        <el-table-column
          prop="GranuleSill"
          label="颗粒物排放限值(mg/m³)"
          width="140"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="CGranuleMax"
          label="日最高颗粒物排放浓度(mg/m³)"
          width="160"
          align="center"
          header-align="center"
        >
          <template slot-scope="{row}">
            <div v-if="dateRange===row.AbnormalOfflineDays ">-</div>
            <div v-else>{{ row.CGranuleMax }}</div>
          </template>

        </el-table-column>
        <el-table-column
          prop="CGranuleMaxDate"
          label="最高颗粒物浓度日期"
          width="130"
          align="center"
          header-align="center"
        >
          <template slot-scope="{row}">
            <div v-if="dateRange===row.AbnormalOfflineDays ">-</div>
            <div v-else>{{ row.CGranuleMaxDate ? row.CGranuleMaxDate.substr(0, 10) : '' }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="CGranuleAvgOnline"
          label="在线日平均颗粒物排放浓度(mg/m³)"
          width="200"
          align="center"
          header-align="center"
        >
        <template slot-scope="{row}">
          <div v-if="dateRange===row.AbnormalOfflineDays ">-</div>
          <div v-else>{{ row.CGranuleAvgOnline }}</div>
        </template>
        </el-table-column>
        <el-table-column
          prop="HydrocarbonSill"
          label="非甲烷排放限值(mg/m³)"
          width="140"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="CHydrocarbonMax"
          label="日最高非甲烷排放浓度(mg/m³)"
          width="160"
          align="center"
          header-align="center"
        >
          <template slot-scope="{row}">
            <div v-if="dateRange===row.AbnormalOfflineDays ">-</div>
            <div v-else>{{ row.CHydrocarbonMax }}</div>
          </template>

        </el-table-column>
        <el-table-column
          prop="CHydrocarbonMaxDate"
          label="最高非甲烷浓度日期"
          width="130"
          align="center"
          header-align="center"
        >
          <template slot-scope="{row}">
            <div v-if="dateRange===row.AbnormalOfflineDays ">-</div>
            <div v-else>{{ row.CHydrocarbonMaxDate ? row.CHydrocarbonMaxDate.substr(0, 10) : '' }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="CHydrocarbonAvgOnline"
          label="在线日平均非甲烷排放浓度(mg/m³)"
          width="200"
          align="center"
          header-align="center"
        >
        <template slot-scope="{row}">
          <div v-if="dateRange===row.AbnormalOfflineDays ">-</div>
          <div v-else>{{ row.CHydrocarbonAvgOnline }}</div>
        </template>
        </el-table-column>


        <el-table-column
          prop="ExcessNum"
          label="超标天数"
          width="100"
          sortable="custom"
          align="center"
          header-align="center"
        />
        <el-table-column
          prop="AbnormalOfflineDays"
          label="异常离线天数"
          sortable="custom"
          align="center"
          header-align="center"
        />
      </el-table>
    </el-col>
    <!-- 工具条 -->
    <el-col :span="24" class="toolbar">
      <el-pagination
        small
        background
        @size-change="(v) => handlePageChange(v, 'size')"
        @current-change="(v) => handlePageChange(v, 'page')"
        :current-page.sync="filter.page"
        :page-sizes="filter.pageSizes"
        layout="total, sizes, prev, pager, next, jumper"
        :total="datalist.total"
        style="display: inline-block; margin-left: 15px"
      >
      </el-pagination>
    </el-col>
  </section>
</template>
<script>
import {mapState} from "vuex";
import {join, export_json, underline} from "@/util/index";
import dayjs from "dayjs";

export default {
  data() {
    return {
      loading: false,
      datalist: {},
      mn: null,
      typ: null,
      owner: null,
      filter: {
        option: true,
        HealthCodeColor: null,
        peorid: null,
        by: "status",
        mode: "asc",
        alarmPage: 1,
        page: 1,
        alarmSize: 20,
        size: 20,
        pageSizes: [20, 30, 50],
      },
      dateRange: 0
    };
  },
  computed: {
    ...mapState(["clientHeight", "customerTree"]),
    ...mapState({
      orgProps: (state) =>
        Object.assign({}, state.props, {label: "Org", checkStrictly: true}),
    }),
  },
  created() {
    this.getData();
  },
  methods: {
    getData: function () {
      this.loading = true;
      var para = {
        StartAt: (this.filter.page - 1) * this.filter.size,
        Size: this.filter.size,
        Typ: 2,
        SortBy: this.filter.by,
        SortMode: this.filter.mode,
      };
      para.Param = {};
      if (this.filter.HealthCodeColor) {
        para.Param["HealthCodeColor"] = this.filter.HealthCodeColor;
      }
      if (this.filter.mn) {
        para.Param["mn"] = this.filter.mn;
      }
      if (this.filter.owner && this.filter.owner.length !== 0) {
        para.Param["Owner"] = join(this.filter.owner);
      }
      if (!this.filter.peorid) {
        this.filter.peorid = [];
        const now = new Date();
        now.setDate(now.getDate() - 1);
        now.setHours(0, 0, 0, 0);
        this.filter.peorid[1] = now.getTime();
        now.setDate(now.getDate() - 30);
        this.filter.peorid[0] = now.getTime();
      }
      para.Param["WithCEmissionsMaxDate"] = true;
      para.Param["StartAt"] = this.filter.peorid[0] / 1000;
      para.Param["EndAt"] = this.filter.peorid[1] / 1000;
      this.dateRange = dayjs(this.filter.peorid[1]).diff(this.filter.peorid[0], 'day') + 1
      this.$post("admin/listCurAlarm/locale", para).then((res) => {
        this.datalist = res;
        this.loading = false;
      });
    },
    handleSort(c) {
      if (c.order) {
        this.filter.by = underline(c.prop);
        this.filter.mode = c.order.replace(/ending/g, "");
      }
      this.getData();
    },
    openExportSizeInput() {
      this.$prompt(`请输入导出的数据量（总量：${this.datalist.total} 条）`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0-9]*$/,
        inputErrorMessage: '只能填入数字'
      }).then(({value}) => {
        this.handleDownload(parseInt(value))
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消'
        })
      })
    },
    handleDownload(num) {
      var para = {
        StartAt: 0,
        Size: num,
        Typ: 2,
        SortBy: this.filter.by,
        SortMode: this.filter.mode,
      };
      para.Param = {
        IsDownload: true
      };
      if (this.filter.HealthCodeColor) {
        para.Param["HealthCodeColor"] = this.filter.HealthCodeColor;
      }
      if (this.filter.mn) {
        para.Param["mn"] = this.filter.mn;
      }
      if (this.filter.owner && this.filter.owner.length !== 0) {
        para.Param["Owner"] = join(this.filter.owner);
      }
      if (!this.filter.peorid) {
        this.filter.peorid = [];
        const now = new Date();
        now.setDate(now.getDate() - 1);
        now.setHours(0, 0, 0, 0);
        this.filter.peorid[1] = now.getTime();
        now.setDate(now.getDate() - 30);
        this.filter.peorid[0] = now.getTime();
      }
      para.Param["WithCEmissionsMaxDate"] = true;
      para.Param["StartAt"] = this.filter.peorid[0] / 1000;
      para.Param["EndAt"] = this.filter.peorid[1] / 1000;
      this.$post("admin/listCurAlarm/locale", para).then((res) => {
        let url = res.url
        const link = document.createElement('a')
        link.setAttribute('href', url)
        link.click()
      });
    },
    handleRefresh() {
      this.filter.page = 1;
      this.filter.HealthCodeColor = null;
      this.filter.mn = null;
      this.filter.owner = null;
      this.getData();
    },
    handlePageChange(val, field) {
      this.filter[field] = val;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.fa-exclamation-triangle {
  color: #ebb460;
}

.fa-bell {
  color: #f56c6c;
}

.el-icon-s-data {
  color: #8896b3;
}

.el-icon-orange {
  color: #409eff;
}
</style>
